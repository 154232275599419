import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <HomeSection />
      <ContentSection />
      <TaxonomySection />
      <TeamSection />
      <Footer />
    </div>
  );
}

function Navbar() {
  return (
    <nav className="Navbar">
      <ul>
        <li><a href="#home">Home</a></li>
        <li><a href="#content">Survey Findings</a></li>
        <li><a href="#taxonomy">Taxonomy</a></li>
        <li><a href="#team">Our Team</a></li>
      </ul>
    </nav>
  );
}

function HomeSection() {
  return (
    <section id="home" className="HomeSection">
      <h1>Survey Topic Overview</h1>
      <p>Key challenges in the field...</p>
      <p>Recent trends and advancements...</p>
      <p>Future research directions...</p>
    </section>
  );
}

function ContentSection() {
  return (
    <section id="content" className="ContentSection">
      <h2>Survey Findings</h2>
      <p>Summarized findings of the survey papers...</p>
    </section>
  );
}

function TaxonomySection() {
  return (
    <section id="taxonomy" className="TaxonomySection">
      <h2>Taxonomy</h2>
      <table>
        <thead>
          <tr>
            <th>Paper</th>
            <th>Domain</th>
            <th>Methodology</th>
            <th>Findings</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Paper 1</td>
            <td>Domain A</td>
            <td>Methodology A</td>
            <td>Findings A</td>
          </tr>
          <tr>
            <td>Paper 2</td>
            <td>Domain B</td>
            <td>Methodology B</td>
            <td>Findings B</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}

function TeamSection() {
  return (
    <section id="team" className="TeamSection">
      <h2>Our Team</h2>
      <div className="TeamMembers">
        <div className="TeamMember">
          <img src="path_to_photo" alt="Alessandro S." />
          <h3>Alessandro S.</h3>
          <p>Brief bio...</p>
        </div>
        <div className="TeamMember">
          <img src="path_to_photo" alt="Charitha Vennapusala" />
          <h3>Charitha Vennapusala</h3>
          <p>Brief bio...</p>
        </div>
         <div className="TeamMember">
          <img src="path_to_photo" alt="Akshitha Poreddy" />
          <h3>Akshitha Poreddy</h3>
          <p>Brief bio...</p>
        </div>
         <div className="TeamMember">
          <img src="path_to_photo" alt="Rohanth Gundu" />
          <h3>Rohanth Gundu</h3>
          <p>Brief bio...</p>
        </div>
      </div>
    </section>
  );
}

function Footer() {
  return (
    <footer className="Footer">
      <p>&copy; 2024 Group 11 website</p>
    </footer>
  );
}

export default App;

